<section class="courses-area pt-100 pb-70" id="products">
    <div class="container">
        <!-- <div class="section-title text-start">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Online Courses</h2>
            <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div> -->
        <div class="section-title text-start">
            <span class="sub-title">Products</span>
            <h2>Our Products</h2>
            <a class="default-btn"
                ><i class="bx bx-show-alt icon-arrow before"></i
                ><span class="label">View All</span
                ><i class="bx bx-show-alt icon-arrow after"></i
            ></a>
        </div>
        <ngx-tabset>
            <ngx-tab>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/course1.webp"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Fire and Safety Sign Boards</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/course2.webp"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Fire Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/course3.webp"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Alarm System</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/course4.webp"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Safety Equipment & PPEs</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses9.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >UL Fire Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses10.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >ABC Fire Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses11.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >BC Powder Fire Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses12.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Water & Foam Fire Extinguisher</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses13.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >CO2 Based Fire Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses14.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Clean Agent Fire Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses15.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Lithium-ion Fire Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses16.png"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Special Application Fire
                                        Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30 shadow-lg">
                            <div class="courses-image">
                                <a class="d-block"
                                    ><img
                                        src="assets/img/courses/courses17.jpg"
                                        alt="image"
                                /></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block"
                                        >Automatic Modular Type Fire
                                        Extinguishers</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </ngx-tab>
        </ngx-tabset>
    </div>
</section>
