<app-header-style-one></app-header-style-one>

<app-hometwo-main-banner></app-hometwo-main-banner>

<app-homeone-about></app-homeone-about>

<app-homeone-courses></app-homeone-courses>

<div class="instructor-area bg-color2 pt-5 pb-70" id="clients">
    <div class="container">
        <div class="section-title">
            <h2>Our Clients</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<app-contact-page></app-contact-page>
<!-- <app-become-instructor-partner></app-become-instructor-partner>

<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div> -->

<!-- <div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div> -->
