<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class="bx bx-map"></i>
                            <a href="#">Excel Fire Services, Ahmedabad</a>
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i>
                            <a href="tel:+919825577598">+91 98255 77598</a>
                            <a href="tel:+919426767877">+91 94267 67877</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i>
                            <a href="mailto:excelfireservices@gmail.com"
                                >excelfireservices@gmail.com</a
                            >
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-pinterest-alt"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Web Design</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>
                            To get the latest news and latest updates from us.
                        </p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input
                                type="email"
                                class="input-newsletter"
                                placeholder="Enter your email"
                                name="EMAIL"
                            />
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"
                    ><img src="assets/img/logo-bg-rmv.png" alt="image"
                /></a>
            </div>
            <p>
                Copyright © 2024 <a href="#">Excel Fire Services.</a> All Rights
                Reserved.
            </p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
