import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-header-style-one",
    templateUrl: "./header-style-one.component.html",
    styleUrls: ["./header-style-one.component.scss"],
})
export class HeaderStyleOneComponent implements OnInit {
    constructor() {}
    menu_previous_active = 0;
    menu = [
        {
            menu_name: "Home",
            scrollName: "home_main",
            id: 0,
            activeClass: "active",
        },
        {
            menu_name: "About",
            scrollName: "home_about",
            id: 1,
            activeClass: "",
        },
        {
            menu_name: "Products",
            scrollName: "products",
            id: 2,
            activeClass: "",
        },
        {
            menu_name: "Clients",
            scrollName: "clients",
            id: 3,
            activeClass: "",
        },
        {
            menu_name: "Contact",
            scrollName: "contact",
            id: 4,
            activeClass: "",
        },
    ];

    ngOnInit(): void {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    Menu_click(id) {
        this.classApplied = !this.classApplied;
        let menu_link = this.menu[id].scrollName;
        document.getElementById(menu_link).scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
        this.menu[this.menu_previous_active].activeClass = "";
        this.menu_previous_active = id;
        this.menu[id].activeClass = "active";
    }
}
