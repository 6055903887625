<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner13.png" alt="image" />
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner14.png" alt="image" />
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner15.png" alt="image" />
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner16.png" alt="image" />
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner17.png" alt="image" />
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner18.png" alt="image" />
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner19.png" alt="image" />
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/partner/partner20.png" alt="image" />
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
