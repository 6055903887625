<div class="about-area ptb-100" id="home_about">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img
                        src="assets/img/about/about5.jpg"
                        class="shadow"
                        alt="image"
                    />
                    <img
                        src="assets/img/about/about6.jpg"
                        class="shadow"
                        alt="image"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>
                        Non-flammable carbon dioxide gas under extreme pressure.
                    </h2>
                    <h6>
                        Ordinary sodium potassium bicarbonate, urea potassium
                        bicarbonate, and potassium chloride base agents.
                    </h6>
                    <p>
                        A fire extinguisher is a handheld active fire protection
                        device usually filled with a dry or wet chemical used to
                        extinguish or control small fires, often in emergencies.
                        It is not intended for use on an out-of-control fire,
                        such as one which has reached the ceiling, endangers the
                        user (i.e., no escape route, smoke, explosion hazard,
                        etc.), or otherwise requires the equipment, personnel,
                        resources or expertise of a fire brigade.
                    </p>
                    <div class="features-text">
                        <!-- <h5>
                            <i class="bx bx-planet"></i>A place where you can
                            achieve
                        </h5> -->
                        <p>
                            Typically, a fire extinguisher consists of a
                            hand-held cylindrical pressure vessel containing an
                            agent that can be discharged to extinguish a fire.
                            Fire extinguishers manufactured with non-cylindrical
                            pressure vessels also exist but are less common.
                        </p>
                    </div>
                    <!-- <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
